@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

@font-face {
  font-family: $fontSecondary;
  src: url('../assets/fonts/longjohnson.woff2') format('woff2'),
        url('../assets/fonts/longjohnson.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

