@import '../../../styles/variables';
@import '../../../styles/mixins';

.about {
  padding: 6rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 6rem);
  background-image: url(../../../assets/about-bg.jpg);
  background-repeat: no-repeat;
  background-size: 200% auto;
  background-position: 50% 0;
  position: relative;
  text-align: center;

  @include minDevice($minTablet) {
    background-size: cover;
    padding: 10rem 3rem;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $firefly;
    opacity: 0.8;
    z-index: 1;
  }
  h1,
  .description,
  .social,
  .contact {
    position: relative;
    z-index: 2;
  }
  .social {
    p {
      margin: 3rem 0 2rem;
    }
    ul.links {
      margin-bottom: 2rem;

      @include minDevice($minTablet) {
        margin-bottom: 3rem;
      }
    }
  }
  .contact {
    p {
      margin: 2rem 0;
    }
    .info {
      color: $turquoise;
    }
    .emoji {
      margin: 0;
      font-size: 3rem;
    }
  }
}
