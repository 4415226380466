// Colors
$white: #fff;
$black: #000;
$turquoise: #33d9b2;
$firefly: #082121;

// font
$fontPrimary: 'Press Start 2P', cursive, Arial, Helvetica, sans-serif;
$fontSecondary: 'Long Johnson';

// Sizes
$minDesktop: 1281px;
$maxLargeTablet: 1280px;
$minLargeTablet: 1025px;
$maxTablet: 1024px;
$minTablet: 769px;
$maxMobile: 768px;