@import '../../../styles/variables';
@import '../../../styles/mixins';

.header {
  background-color: $firefly;
  padding: 0 4rem;
  position: fixed;
  top: 0;
  left: -3rem;
  right: -3rem;
  z-index: 10;
  box-shadow: 0 0 3rem $turquoise;
  border-bottom: 1px solid $turquoise;

  @include minDevice($minTablet) {
    padding: 0 5rem;
  }
  &::before {
    content: "";
    width: 60rem;
    height: 50rem;
    border-radius: 30rem;
    position: absolute;
    bottom: 10rem;
    left: 0;
    transform: translateX(-50%);
    animation: 5s infinite alternate ease-in-out glow;
    display: none;
    opacity: 0.8;

    @include minDevice($minLargeTablet) {
      display: block;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(5deg, rgba(51,217,178,0) 0%, rgba(51,217,178,1) 80%);
    z-index: 1;
    opacity: 0.2;
    transition: .2s all ease-in-out;
  }
  &.left {
    &::after {
      background: linear-gradient(-5deg, rgba(51, 217, 178, 0) 0%, rgba(51, 217, 178, 1) 80%);
    }
  }
  &.center {
    &::after {
      background: rgba(51, 217, 178, 0.7);
    }
  }
  .navigation {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      &:not(.active) {
        a {
          opacity: 0.8;
        }
      }
      &.active {
        a {
          text-shadow: 0 0 5rem $turquoise,
            -1px -1px 0 $turquoise,
            1px -1px 0 $turquoise,
            -1px 1px 0 $turquoise,
            1px 1px 0 $turquoise;
        }
      }
      a {
        display: flex;
        align-items: center;
        font-size: 2.5rem;
        line-height: 6rem;
        height: 6rem;
        text-transform: uppercase;
        padding: 0 1rem;
        margin-left: 0.3rem;
        color: $firefly;
        font-family: $fontSecondary;
        text-decoration: none;
        letter-spacing: 3px;
        text-shadow:
          -1px -1px 0 $turquoise,
          1px -1px 0 $turquoise,
          -1px 1px 0 $turquoise,
          1px 1px 0 $turquoise;
        position: relative;
        transition: .12s all ease-in-out;

        &:not(.logo)::before {
          content: attr(title);
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -46%);
        }
      }
      .logo {
        img {
          display: block;
          height: 3rem;
        }
      }
    }
  }
}

@keyframes glow {
  0% {
    left: 0;
    box-shadow: 0 0 10rem $turquoise;
  }
  50% {
    box-shadow: 0 0 10rem $turquoise;
  }
  100% {
    left: 100%;
    box-shadow: 0 0 10rem $turquoise;
  }
}
