@import '../../../styles/variables';
@import '../../../styles/mixins';

.fullscreen-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6rem 3rem;
  min-height: calc(100vh - 6rem);
  position: relative;
  background-size: 100% auto;
  background-position: 50% 0;
  background-repeat: no-repeat;
  overflow: hidden;

  @include minDevice($minTablet) {
    padding: 10rem 3rem;
  }
  .background-image,
  .video-container  {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    pointer-events: none;

    &::after {
      content: "";
      position: absolute;
      top: calc(100% - 20vh);
      left: 0;
      right: 0;
      height: 20vh;
      background: linear-gradient(180deg, rgba(8,33,33,0) 0%, rgba(8,33,33,1) 80%);
      z-index: 1;
    }
    img {
      width: 100%;
    }
  }
  .video-container {
    display: none;
    height: 0;
    padding-top: 56.25%;

    @include minDevice($minLargeTablet) {
      display: block;

      &::before {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 1000vh;
        background-color: $firefly;
      }
    }
    iframe {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $firefly;
    opacity: 0.7;
    z-index: 1;
  }
  > * {
    position: relative;
    z-index: 3;
  }
}
