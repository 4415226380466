p {
  font-size: 1.4rem;
  line-height: 1.8;
  color: $white;
  max-width: 100rem;
  margin: 0 0 1.5rem;
  text-shadow: 0 0 1rem $turquoise;

  @include minDevice($minTablet) {
    font-size: 1.8rem;
  }
  &.small {
    margin: 0 auto 1.5rem;
    max-width: 80rem;
    font-size: 1.2rem;
    color: $turquoise;
  }
}

h1 {
  display: inline-block;
  font-size: 4.6rem;
  color: $firefly;
  text-transform: uppercase;
  font-family: $fontSecondary;
  letter-spacing: 6px;
  margin: 0 0 5rem;
  text-shadow:
          0 0 40rem $turquoise,
          -1px -1px 0 $turquoise,
          1px -1px 0 $turquoise,
          -1px 1px 0 $turquoise,
          1px 1px 0 $turquoise;
  position: relative;

  @include minDevice($minTablet) {
    font-size: 6.6rem;
  }
  &::before {
    content: attr(title);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -42%);
  }
}

.assistive-text {
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -1rem;
}

.line-through {
  text-decoration: line-through;
}
