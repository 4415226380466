@mixin minDevice($type) {
  @media only screen and (min-width: $type) {
    @content;
  }
}

@mixin maxDevice($type) {
  @media only screen and (max-width: $type) {
    @content;
  }
}

@mixin minMaxDevice($min, $max) {
  @media only screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}
