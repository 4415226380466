@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.release-item {
  &.cinema {
    .fullscreen-background {
      > *:not(.youtube-video-container):not(.background-image) {
        opacity: 0.1;
      }
      &::before {
        opacity: 0.95;
      }
    }
    .youtube-video-container {
      max-width: 100rem;

      .cinema-mode-trigger {
        button {
          background-color: $firefly;
          color: $turquoise;
          box-shadow: 0 0 1rem $turquoise;

          &:focus {
            box-shadow: 0 0 2rem $turquoise;
          }
        }
      }
    }
  }
  .youtube-video-container {
    width: 100%;
    max-width: 60rem;
    transition: .12s max-width ease-in-out;

    .youtube-video-wrapper {
      margin-bottom: 2rem;

      .youtube-video {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        height: 0;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    .cinema-mode-trigger {
      display: none;

      @include minDevice($minTablet) {
        display: block;
      }
    }
  }
}
