@import '../../../styles/variables';
@import '../../../styles/mixins';

ul.links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100rem;

  li {
    a {
      display: block;
      padding: 1.5rem;

      @include minDevice($minTablet) {
        padding: 1rem 2rem;
      }
      img {
        transition: .2s all ease-in-out;
        display: block;
        width: 100%;
        max-width: 120px;

        @include minDevice($minTablet) {
          max-width: 150px;
        }
      }
      &:hover {
        img {
          transform: scale(1.05);
        }
      }
    }
  }
}
