* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: $firefly;
  font-family: $fontPrimary;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 2rem;
  padding-top: 6rem;
}

.content *:focus {
  box-shadow: 0 0 2rem $turquoise;
  outline: 0;
}

.turquoise {
  color: $turquoise;
}
